<template>
  <div class="user">
    <!-- 搜索区域 -->
    <div class="header">
      <el-input class="input" v-model="input" placeholder="输入名称或手机号"></el-input>
      <el-select class="input" v-model="select_chain_id" placeholder="请选择机构">
        <el-option
          v-for="item in chainData"
          :key="item.shop_chain_id"
          :label="item.chain_name"
          :value="item.shop_chain_id"
        ></el-option>
      </el-select>
      <div class="button-two">
        <el-button type="primary" @click="searchInp">查询</el-button>
        <el-button type="primary">
          <router-link to="/user/details" class="goto">新增用户</router-link>
        </el-button>
      </div>
    </div>
    <!-- 表格区域 -->
    <div class="wrapper">
      <el-table :data="tableData" v-loading="loading" style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="用户名称" show-overflow-tooltip width="200"></el-table-column>
        <el-table-column prop="phone" label="手机号码" width="200"></el-table-column>
        <el-table-column prop="is_enable" label="是否启用" width="100"></el-table-column>
        <el-table-column prop="created" label="添加日期"></el-table-column>
        <el-table-column prop="chain_name" label="所属机构" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <el-button size="mini" @click="Code(scope.row)">二维码</el-button>
            <el-button size="mini" @click="handleEdit(scope.row)" :disabled="scope.row.disabled">编辑</el-button>
            <el-button size="mini" @click="handleAlter(scope.row)" :disabled="scope.row.disabled">{{scope.row.is_enable === '已启用' ? '禁' : '启'}}用</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" :disabled="scope.row.disabled">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="二维码" :visible.sync="dialogCode" width="360px" center :before-close="close">
      <el-image style="width: 310px; height: 310px;" :src="picUrl"></el-image>
      <el-button style="width: 310px" type="success" @click="down" plain>下载图片</el-button>
    </el-dialog>
    <!-- 分页 -->
    <MyPagination :total="total" :pageSize="query_info.limit" @changePage="changePage" :currentPage="query_info.page" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { shopGetlist, shopStatus, delUser, UserQrCode, shopChainlist } from '../../../request/api'
import MyPagination from '../../../components/MyPagination.vue'

export default {
  inject: ['reload'],
  components: {
    MyPagination
  },
  data () {
    return {
      input: '',
      tableData: [],
      chainData: [],
      select_chain_id: '',
      query_info: {
        chain_id: '',
        limit: 10,
        page: 1
      },
      total: 0,
      picUrl: 'https://',
      dialogCode: false,
      loading: false
    }
  },
  computed: {
    ...mapState('m_login', ['loginData'])
  },
  methods: {
    async getUserList () {
      try {
        this.loading = true
        const res = await shopGetlist(this.query_info.chain_id, this.query_info.limit, this.query_info.page, this.input, this.select_chain_id)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        res.data.data.userList.map((element, index, arr) => {
          element.add_chain_id === this.query_info.chain_id ? arr[index].disabled = false : arr[index].disabled = true
          element.is_enable === 0 ? arr[index].is_enable = '已启用' : arr[index].is_enable = '已禁用'
        })
        this.tableData = (res.data.data.userList)
        this.total = res.data.data.totalNumber
      } finally {
        this.loading = false
      }
    },
    // 搜索按钮
    searchInp () {
      this.query_info.page = 1
      this.tableData = []
      this.getUserList()
    },
    // 编辑操作
    handleEdit (row) {
      this.$router.push(
        {
          path: '/user/details',
          query: {
            shop_user_id: row.shop_user_id
          }
        }
      )
    },
    async handleAlter (row) {
      let postData = {
        shop_user_id: row.shop_user_id,
        is_enable: row.is_enable === '已启用' ? 1 : 0
      }
      const res = await shopStatus(postData)
      if (res.status !== 200 || res.data.code !== '1000') {
        this.$message.error(res.data.msg)
        return
      }
      this.getUserList()
      this.$message({
        type: 'success',
        message: '修改成功！'
      })
      this.reload()
    },
    handleDelete (row) {
      this.$confirm('是否删除该用户？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        let postData = {
          shop_user_id: row.shop_user_id
        }
        const res = await delUser(postData)
        if (res.status !== 200 || res.data.code !== '1000') {
          this.$message.error(res.data.msg)
          return
        }
        this.$message({
          type: 'success',
          message: '删除成功！'
        })
        this.reload()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 自定义事件 取到分页的页码
    changePage (page) {
      this.query_info.page = page
      this.tableData = []
      this.getUserList()
    },
    Code (row) {
      if (row.is_enable === '已禁用') {
        this.$message({
          type: 'warning',
          message: '该客户已禁用'
        })
        return
      }
      let postData = {
        shop_user_id: row.shop_user_id
      }
      const res = UserQrCode(postData)
      res.then(code => {
        if (code.status !== 200 || code.data.code !== '1000') this.$message.error(code.data.msg)
        this.picUrl = this.picUrl + code.data.data
        this.dialogCode = true
      })
    },
    // 关闭弹窗调用
    close () {
      this.picUrl = 'https://'
      this.dialogCode = false
    },
    // 下载图片
    down () {
      let image = new Image()
      // 解决跨域 canvas污染问题
      image.setAttribute('crossOrigin', 'anonymous')
      image.onload = function () {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        // 绘制2D图
        let context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        let url = canvas.toDataURL('image/png')
        let a = document.createElement('a')
        let event = new MouseEvent('click')
        a.download = '商品图片'
        a.href = url
        a.dispatchEvent(event)
      }
      image.src = this.picUrl
    },
    // 获取小列表
    async getChain() {
      const res = await shopChainlist(this.query_info.chain_id, 999, 1, "")
      if (res.status !== 200 || res.data.code !== "1000") {
        this.$message.error(res.data.msg);
        return;
      }
      this.chainData = res.data.data.chainList
    }
  },
  created () {
    this.query_info.chain_id = this.loginData.chain_id
  },
  mounted () {
    this.getChain()
    this.getUserList()
  }
}
</script>

<style lang="less" scoped>
.user {
  margin: 20px;
  .header {
    display: flex;
    .input {
      width: 240px;
      margin-right: 10px;
    }
    .button-two {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      margin-right: 20px;
      .goto {
        color: #fff;
      }
    }
  }
  .wrapper {
    margin: 20px 0;
  }
}
</style>